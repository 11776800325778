'use client';
import React, { useState } from 'react';
import { Modal } from 'flowbite-react';


const CampaignForm = ({ openModal, setOpenModal, setFormSubmitted }) => {
    // State to manage form fields
    const [formValues, setFormValues] = useState({
        advertiser: '',
        title: '',
        description: '',
        allowedTrafficChannels: '',
        previewUrl: '',
        conversationTracking: '',
        defaultCampaignUrl: '',
        mostUsedUrlTokens: ['{click_id}', '{camp_id}', '{publisher_id}', '{source}', '{gaid}', '{idfa}', '{app_name}', '{p1}', '{p2}'],
        defaultGoalName: '',
        redirectType: '',
        uniqueClickSessionDuration: '',
    });

    const handleClear = () => {
        setFormValues({
            advertiser: '',
            title: '',
            description: '',
            allowedTrafficChannels: '',
            previewUrl: '',
            conversationTracking: '',
            defaultCampaignUrl: '',
            mostUsedUrlTokens: ['{click_id}', '{camp_id}', '{publisher_id}', '{source}', '{gaid}', '{idfa}', '{app_name}', '{p1}', '{p2}'],
            defaultGoalName: '',
            redirectType: '',
            uniqueClickSessionDuration: '',
        })
    }
    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        // Close the modal after submission
        setOpenModal(false);
        setFormSubmitted(true)

        try {
            // Make the POST request
            await handlePostRequest(formValues);

            // Reset form values
            setFormValues({
                advertiser: '',
                title: '',
                description: '',
                allowedTrafficChannels: '',
                previewUrl: '',
                conversationTracking: '',
                defaultCampaignUrl: '',
                mostUsedUrlTokens: ['{click_id}', '{camp_id}', '{publisher_id}', '{source}', '{gaid}', '{idfa}', '{app_name}', '{p1}', '{p2}'],
                defaultGoalName: '',
                redirectType: '',
                uniqueClickSessionDuration: '',
            });

        } catch (error) {
            console.error('Error during form submission:', error.message);
        }
    };

    // Function to handle the actual POST request
    const handlePostRequest = async (formValues) => {
        try {
            const response = await fetch('https://tackiertaffic.com/createcampaign.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // add any other headers if needed
                },
                body: JSON.stringify(formValues),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Handle the response data as needed
            const responseData = await response.json();
            console.log(responseData);
        } catch (error) {
            console.error('Error during POST request:', error.message);
            throw error; // Re-throw the error to be caught by the form submission catch block
        }
    };

    return (
        <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
            <Modal.Header>Campaign Form</Modal.Header>
            <Modal.Body>
                {/* Form */}
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Advertiser */}
                    <div>
                        <label htmlFor="advertiser" className="block text-sm font-medium text-gray-700">
                            Advertiser
                        </label>
                        <input
                            type="text"
                            id="advertiser"
                            name="advertiser"
                            value={formValues.advertiser}
                            onChange={(e) => setFormValues({ ...formValues, advertiser: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>

                    {/* Title */}
                    <div>
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                            Title
                        </label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={formValues.title}
                            onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>

                    {/* Description */}
                    <div>
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                            Description (Optional)
                        </label>
                        <textarea
                            id="description"
                            name="description"
                            value={formValues.description}
                            onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                        />
                    </div>

                    {/* Allowed Traffic Channels */}
                    <div>
                        <label htmlFor="allowedTrafficChannels" className="block text-sm font-medium text-gray-700">
                            Allowed Traffic Channels
                        </label>
                        <input
                            type="text"
                            id="allowedTrafficChannels"
                            name="allowedTrafficChannels"
                            value={formValues.allowedTrafficChannels}
                            onChange={(e) => setFormValues({ ...formValues, allowedTrafficChannels: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>

                    {/* Preview URL */}
                    <div>
                        <label htmlFor="previewUrl" className="block text-sm font-medium text-gray-700">
                            Preview URL
                        </label>
                        <input
                            type="text"
                            id="previewUrl"
                            name="previewUrl"
                            value={formValues.previewUrl}
                            onChange={(e) => setFormValues({ ...formValues, previewUrl: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>

                    {/* Conversation Tracking */}
                    <div>
                        <label htmlFor="conversationTracking" className="block text-sm font-medium text-gray-700">
                            Conversation Tracking
                        </label>
                        <input
                            type="text"
                            id="conversationTracking"
                            name="conversationTracking"
                            value={formValues.conversationTracking}
                            onChange={(e) => setFormValues({ ...formValues, conversationTracking: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>

                    {/* Default Campaign URL */}
                    <div>
                        <label htmlFor="defaultCampaignUrl" className="block text-sm font-medium text-gray-700">
                            Default Campaign URL
                        </label>
                        <input
                            type="text"
                            id="defaultCampaignUrl"
                            name="defaultCampaignUrl"
                            value={formValues.defaultCampaignUrl}
                            onChange={(e) => setFormValues({ ...formValues, defaultCampaignUrl: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>

                    {/* Most Used URL tokens */}
                    <div>
                        <label htmlFor="mostUsedUrlTokens" className="block text-sm font-medium text-gray-700">
                            Most Used URL tokens
                        </label>
                        <input
                            type="text"
                            id="mostUsedUrlTokens"
                            name="mostUsedUrlTokens"
                            value={formValues.mostUsedUrlTokens}
                            onChange={(e) => setFormValues({ ...formValues, mostUsedUrlTokens: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>

                    {/* Default Goal Name */}
                    <div>
                        <label htmlFor="defaultGoalName" className="block text-sm font-medium text-gray-700">
                            Default Goal Name
                        </label>
                        <input
                            type="text"
                            id="defaultGoalName"
                            name="defaultGoalName"
                            value={formValues.defaultGoalName}
                            onChange={(e) => setFormValues({ ...formValues, defaultGoalName: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>

                    {/* Redirect Type */}
                    <div>
                        <label htmlFor="redirectType" className="block text-sm font-medium text-gray-700">
                            Redirect Type
                        </label>
                        <input
                            type="text"
                            id="redirectType"
                            name="redirectType"
                            value={formValues.redirectType}
                            onChange={(e) => setFormValues({ ...formValues, redirectType: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>

                    {/* ... Repeat the pattern for other form fields ... */}

                    {/* Unique Click Session Duration */}
                    <div>
                        <label htmlFor="uniqueClickSessionDuration" className="block text-sm font-medium text-gray-700">
                            Unique Click Session Duration
                        </label>
                        <input
                            type="text"
                            id="uniqueClickSessionDuration"
                            name="uniqueClickSessionDuration"
                            value={formValues.uniqueClickSessionDuration}
                            onChange={(e) => setFormValues({ ...formValues, uniqueClickSessionDuration: e.target.value })}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>

                    {/* Submit button inside the form */}
                    <div className="flex gap-2 w-full">
                        <button type="submit" className="w-full bg-blue-500 text-white hover:opacity-90 p-2 rounded-md">
                            Submit
                        </button>
                        <button onClick={() => handleClear()} className="w-full hover:bg-blue-500 hover:text-white border-2 border-blue-500 p-2 rounded-md">
                            Clear All
                        </button>
                    </div>

                </form>
            </Modal.Body>
            {/* <Modal.Footer>
                <button type="submit">Submit</button>
                <Button color="gray" onClick={() => setOpenModal(false)}>
                    Decline
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
};

export default CampaignForm;