import React, { useState } from 'react'

const ReportTable = ({ data, loading }) => {
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSort = (column) => {
        // If the same column is clicked, toggle the sorting order
        if (column === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If a different column is clicked, set it as the new sorting column
            setSortColumn(column);
            setSortOrder('asc'); // Default to ascending order
        }
    };

    // console.log("datewise data table", data)
    return (
        <>
            <table className="table-auto w-[90%] shadow-2xl mb-10">
                <thead className="sticky top-0 border border-[#212122] bg-[#595652] shadow-xl text-white font-medium">
                    <tr>
                        <th className="font-medium"></th>
                        {/* <th className="px-4 py-2 font-medium">Name</th> */}
                        <th className="px-4 py-2 font-medium">
                            <div onClick={() => handleSort('clicks')} className='flex items-center justify-center cursor-pointer'>
                                Date
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="px-4 py-2 font-medium">
                            <div onClick={() => handleSort('clicks')} className='flex items-center justify-center cursor-pointer'>
                                Impression
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="px-4 py-2 font-medium">
                            <div onClick={() => handleSort('clicks')} className='flex items-center justify-center cursor-pointer'>
                                Clicks
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="px-4 py-2 font-medium">CTR</th>
                    </tr>
                </thead>

                {
                    loading ? <>
                        <tbody className='text-white border border-[#212122]'>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                        </tbody>
                    </>
                        : <>
                            <tbody className='text-white border border-[#212122]'>
                                {
                                    data && data.sort((a, b) => {
                                        if (sortColumn) {
                                            if (sortOrder === 'asc') {
                                                return a[sortColumn] > b[sortColumn] ? 1 : -1;
                                            } else {
                                                return a[sortColumn] < b[sortColumn] ? 1 : -1;
                                            }
                                        }
                                        return 0; // No sorting
                                    }).map((item, index) => {
                                        return (
                                            <>
                                                <tr key={index} className='even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] border border-[#212122]'>
                                                    <td className=" px-4 py-5 text-center">
                                                        <span className='px-3 py-1 rounded-lg text-white text-sm bg-[#368] shadow-xl'>{index + 1}</span>
                                                    </td>
                                                    <td className=" px-4 py-5 text-center">{item.date}</td>
                                                    <td className=" px-4 py-5 text-center">
                                                        {item.impression.toLocaleString()}
                                                    </td>
                                                    <td className=" px-4 py-5 text-center">
                                                        {item.clicks.toLocaleString()}
                                                    </td>
                                                    <td className=" px-4 py-5 text-center">{item.ctr}</td>
                                                </tr >
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </>
                }
            </table >
        </>
    )
}

export default ReportTable
