import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [loggedin, setLoggedin] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const login = async (username, password) => {
        console.log('checking user and pass', username, password)

        try {
            const formdata = new FormData();
            formdata.append("email", username);
            formdata.append("pass", password);

            const requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            const response = await fetch("https://tackiertaffic.com/login_api.php", requestOptions);
            const result = await response.json(); // Parse the JSON response
            console.log('checking reponse', typeof(result.status))
            if (result.status === 1) {
                setLoggedin(true);
                localStorage.setItem("loggedIn", true);
                setErrorMsg("");
                console.log('User successfully logged in');
                return true;
            } else {
                setErrorMsg("Invalid Username or Password");
                setLoggedin(false);
                localStorage.setItem("loggedIn", false);
                console.log('Login failed');
                return false;
            }
        } catch (error) {
            console.error("Error during login:", error);
            setErrorMsg("An error occurred during login");
            return false;
        }
    };

    const logout = () => {
        setLoggedin(false);
        localStorage.removeItem("loggedIn");
    };

    return (
        <AuthContext.Provider value={{ loggedin, errorMsg, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
